import JSONData from "../data/data.json"
import _ from "lodash"
import slug from "slug"

export function issuesForSite(id) {
    var site = getSite(id)
    return JSONData["Issues"].filter(issue => {
        return issue["Parent summary"] === site.company
    }) || []
}

export function allIssues() {
    var issues = JSONData["Issues"]
    // return issues.map(site => {
    //     let nSite = {}
    //     nSite.data = site
    //     nSite.issues = issuesForSite(site)
    //     nSite.stats = computeStatistics(nSite.issues)
    //     nSite.slug = slug(site.company)
    //     return nSite
    // })
    return JSONData["Issues"]
}

export function allSites() {
    var sites =  JSONData["Sites"]
    return sites.map(site => {
        let nSite = {}
        nSite = site
        nSite.issues = issuesForSite(site.id)
        nSite.stats = computeStatistics(nSite.issues)
        nSite.slug = slug(site.company)
        return nSite
    })
}

export function getSite(id){
    return JSONData["Sites"].filter( site => { return site["id"] === id})[0]
}

export function getTestDate(siteName){

    var dates = JSONData["Raw Responses"].filter( response => { return response["Company"] === siteName })
    console.log(dates)
    return dates[0]["Date"]
}

export function computeStatistics(issues) {
    var severity = _.countBy(issues, (o) => {
        return _.get(o, "Impact", "none")
    })
    var groupedByTester = _.groupBy(issues, (o) => { return _.get(o, "Tester") })
    var severityByTester = _.mapValues(groupedByTester, (group) =>
        _.countBy(group, (o) => {
            return _.get(o, "Impact", "none")
        }))

    var category = _.countBy(issues, (o) => {

        var candidate = _.get(o, "Category", "none") !== "Needs Discussion" ? _.get(o, "Category", "none") : "Other"
        return candidate
    })
    return { severity: severity, severityByTester: severityByTester, category: category }
}

export { JSONData }